import { Button, Grid2 } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from "react-type-animation";



const MainApp = ()=> {
    let navigate = useNavigate();
    
    return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <Grid2 alignItems={"center"} justifyContent="center" direction="column" spacing={0} container>
    <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        'Action Robotix - Home',
                        1000, // wait 1s before replacing "Mice" with "Hamsters"
                        'Action Robotix - Missouri Proud',
                        1000,
                        'Action Robotix - Made in STL',
                        1000,
                        'Action Robotix - Ready for FTC',
                        1000
                ]}
                wrapper="span"
            speed={50}
            style={{ fontSize: '2em', display: 'inline-block' }}
            repeat={Infinity}
            >
            </TypeAnimation>
    <Grid2 container 
        spacing={0}
        direction="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
    >
    <Grid2 size={4} alignItems="center" justifyContent="center" direction="column" spacing={0}>
            <Grid2><Button color="primary" variant="contained" style={{"width":"80%"}}>Learn More</Button></Grid2>
    </Grid2>
    <Grid2 item size={8} >
        <Carousel autoPlay={true} width={"70%"} infiniteLoop >
                <div>
                    <img src="https://actionrobotix.com/picapi/getrandomimage?id=1" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="https://actionrobotix.com/picapi/getrandomimage?id=2" />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img src="https://actionrobotix.com/picapi/getrandomimage?id=3" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
    </Grid2>
    <Grid2 alignContent={"left"}>
        <div>
            <h1>About us</h1>
            <p>Action Robotix is a robotics team focused on community and innovation.</p>
            <Button color="primary" variant="contained" style={{"width":"50%"}} onClick={() => navigate("/aboutus")} >Learn More</Button>
        </div>
    </Grid2>
    <Grid2>
        <div>
            <h1>Support Our Team</h1>
            <p>Our team may be small, but we have ambitious goals. To achieve them, we require your assistance. Kindly consider donating to our First Tech Challenge team to support us and motivate others to explore the world of robotics.</p>
            <Button color="primary" variant="contained" style={{"width":"50%"}} onClick={() => navigate("/supportus")}>Donate Now</Button>
        </div>
    </Grid2>
    </Grid2>
    </Grid2>
    
    </>)
}

export default MainApp;