import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar';
import MainApp from './MainApp';
import AboutUs from './AboutUs';

import {
  Outlet,
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import FIRST from './FIRST';
import Projects from './Projects';
import Outreach from './Outreach';
import SupportUs from './SupportUs';
import ContactUs from './ContactUs';
import Footer from './Footer';
import FTC from './FTC';
import FRC from './FRC';
import FLL from './FLL';

const Layout = () => (
  <>
    <ResponsiveAppBar />
    <Outlet />
    <Footer />
  </>
);


const routers = createBrowserRouter([
  {
    element: <Layout />,
    children: [
          { path :"/" , element : <MainApp />},
          { path :"/aboutus", element: <AboutUs />},
          { path :"/FIRST", element : <FIRST />},
          { path :"/projects", element : <Projects />},
          { path :"/outreach", element : <Outreach />},
          { path :"/supportus", element : <SupportUs />},
          { path :"/contactus", element : <ContactUs />},
          { path :"/ftc", element : <FTC />},
          { path :"/frc", element : <FRC />},
          { path :"/fll", element : <FLL />},
          // { path :"/register", element : <CreateUserFormContainer />},
          // { path :"/categories",element : <Categories />},
          // { path :"/booksbycategory",element : <BooksByCategory/>},
          // { path :"/booksbyuser", element : <BooksByUser/>},
          // { path :"/booksbysearch", element : <BooksBySearch/>},
          // { path :"/RecommendationList", element : <RecommendationList/>},
          // { path :"writereview", element : <WriteReview/>},
          // { path :"editreview", element : <EditReview/>},
          // { path :"/bookdetail/:isbn13", element: <BookDetail/>},
          // { path :"/dashboard", element :  <Dashboard/>},
          // { path :"/editprofile" ,element : <EditProfile/>},
          // { path :"/borrowbook" ,element : <BorrowBookForm/>},
          // { path :"/forgot-password-ask-email" ,element : <ForgotPasswordAskForEmail/>},
          // { path :"/reset_password" ,element :  <ForgotPassword/>},
          // { path :"validate_account" ,element :   <ActivateAccount/>},
          // { path :"/contact-us", element : <ContactUs/>},
          // { path :"/admin-dashboard", element :<AdminDashboard/>},
          // { path: "/mostpopularbooks", element :<MostPopularBooks/>},
          // { path: "/booksnearme", element :<BooksNearMe/>}
    ]
  }
]);


function App() {
  return <RouterProvider router={routers} />;
}

export default App;
