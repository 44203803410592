import { Grid2 } from "@mui/material";

const AboutUs = () => {


    return (
    <>
    <div>
        <h1>Our Mission</h1>
    </div>
    <div>
        <p>At Action Robotix, we aim to inspire and empower the next generation through robotics. Core values of teamwork and creativity drive us to achieve our goals of excellence in competition and community. Established in 2024, we are dedicated to promoting STEM education providing opportunities for students to develop their skills. We plan to continue our mission by expanding our efforts and pushing for innovation in the field of robotics.</p>
        <img src = "Images/ftcComp.jpg" height= "200"></img>
    </div>
    <br></br>
    <div>
        <h2>Meet Our Team</h2>
        <h3>Dedication. Expertise. Passion.</h3>
        <p>Our team comprises of 14 members from the Saint Louis area. Each member has unique specialties, including programming, engineering, designing, and outreach.</p>
    </div>
    <Grid2 container size = {12}>
    <Grid2 size = {6}>
   ` <div>
        <img src = "Images/arnavA.png" height = "200"></img>
        <h4>Arnav Anilkumar</h4>
    </div>`
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/arjun.png" height = "200"></img>
            <h4>Arjun Arun</h4>
        </div>
    </Grid2>
    </Grid2>
    
    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/mihir.png" height = "200"></img>
            <h4>Mihir Choudhary</h4>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/adi.png" height = "200"></img>
            <h4>Adithya Ganesh</h4>
        </div>
    </Grid2>
    </Grid2>

    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/aditi.png" height = "200"></img>
            <h4>Aditi Jha</h4>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/ashvik.png" height = "200"></img>
            <h4>Ashvik Kothari</h4>
        </div>
    </Grid2>
    </Grid2>

    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/keshav.png" height = "200"></img>
            <h4>Keshav Pallavur</h4>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/vihaan.png" height = "200"></img>
            <h4>Vihaan Punjabi</h4>
        </div>
    </Grid2>
    </Grid2> 

    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/Naisha.png" height = "200"></img>
            <h4>Naisha Salaria</h4>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/devansh.png" height = "200"></img>
            <h4>Devansh Sharma</h4>
        </div>
    </Grid2>
    </Grid2>   

    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/nikhil.jpeg" height = "200"></img>
            <h4>Nikhil Sharma</h4>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <img src = "Images/ishaan.png" height = "200"></img>
            <h4>Ishaan Vitthala</h4>
        </div>
    </Grid2>
    </Grid2>        
    

    <br></br>
    <div>
        <h2>Accomplishments</h2>
    </div>
    <br></br>
    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <h4>FLL Robotics World Championship</h4>
            <p>2021 - 2022</p>
            <img src = "Images/houston.jpg" height = "200"></img>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <h4>Sunshine Invitational Championship</h4>
            <p>2023 - 2024</p>
            <img src = "Images/sunshineInvational.jpg" height = "200"></img>
        </div>
    </Grid2>
    </Grid2> 

    <Grid2 container size = {12}>
    <Grid2 size = {6}>
        <div>
            <h4>1st Place Champions Award</h4>
            <p>2023 - 2024</p>
            <img src = "Images/coding-cheetahs.jpg" height = "200"></img>
        </div>
    </Grid2>
    <Grid2 size = {6}>
        <div>
            <h4>2nd Place Champions Award</h4>
            <p>2023 - 2024</p>
            <img src = "Images/AR-Regional-Championship-2024.jpg" height = "200"></img>
        </div>
    </Grid2>
    </Grid2>   
    
    </>
    )
    
}

export default AboutUs;