import React from "react";

const Projects = () => {


    return (<>

    <div>
        <h1>Projects</h1>
        <h3>Our team has created numerous solutions meant to address real-world challenges, emphasizing efficiency, feasibility, and practicality. Below is a catalog of the projects we have invisioned, designed, and made practical:</h3>
    </div>
    <br />
    <div>
        <h2>ActiStrap</h2>
        <p>Discover a cutting-edge measuring device for shoe wearers and companies. This innovative tool ensures accurate size estimates for a perfect fit. This project earned us a board meeting with fortune 500 officials.</p>
        <img src="Images/actiStrap.jpg"
        height={"150"}
        width={"1050"}></img>
    </div>
    <div>
        <h2>Top Tennis</h2>
        <p>Top Tennis is an indoor tennis simulator that combines a ball machine with simulation projection, allowing players to enhance their skills anytime and anywhere.</p>
        <img src="Images/TopTennisPic.png"
        height={"310"}
        width={"500"}></img>
    </div>
    <div>
        <h2>Road Turbine</h2>
        <p>This project solution is to harness the kinetic energy of vehicles moving on the road and convert it into electricity.</p>
        <img src="Images/roadTurbine.jpg"
        height={"310"}
        width={"500"}></img>
    </div>
    </>
    )
    
}

export default Projects;