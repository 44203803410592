import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmailIcon from '@mui/icons-material/Email';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import { Grid2 } from "@mui/material";

const ContactUs = () => {

    return (
        <>
        <div>
            <h1>Contact Details</h1>
        </div>
        <br></br>
        <Grid2 container size = {12}>
            <Grid2 size = {6}>
                <div>
                    <img src = "Images/ContactPicture.jpg" height = "300"></img>
                </div>
            </Grid2>
            <Grid2 size = {6}>
                <div>
                    <h4>Contact</h4>
                    <p>Phone: (636) - 579 - 6518</p>
                    <p>Email: actionrobotix@gmail.com</p>
                    <br></br>
                    <h4>Address</h4>
                    <p>Chesterfield, St. Louis, Missouri</p>
                    <br></br>
                    <h4>Contact Hours</h4>
                    <p>Sunday: 2:30 - 4:30</p>
                    <p>Tuesday: 5:30 - 7:30</p>
                    <br></br>
                    <h4>Social Media</h4>
                    <BottomNavigationAction label="" icon={<InstagramIcon />} />
                    <BottomNavigationAction label="" icon={<XIcon />} />
                    <BottomNavigationAction label="" icon={<YouTubeIcon />} />
                </div>
            </Grid2>
        </Grid2>
        <br></br>    
        </>
    )

}

export default ContactUs;