import React from "react";
import { Button } from "@mui/material";
const SupportUs = () => {

    return ( <>
    <div>
        <h1>Support Us</h1>
        <h3>Parts, registration, tools, and other items can be expensive and require regular purchases.</h3>
        <h3>Every dollar raised supports the team's growth and helps finance our objectives.</h3>
    </div>
    <br />
    <div>
        <h2>Your Help</h2>
        <p>Our team needs your help in order to be able to participate in the 2024-25 FTC season. One of the ways you can help is by donating to our team's GoFundMe. We are truly grateful for all aid, and hope we can repay your kindness!</p>
        
        <Button color = "primary" variant = "contained" target="_blank" href="https://www.gofundme.com/f/Robotix-international">Donate Now</Button>
        
        <img src="Images/QRCode.jpg"
        height={"200"}
        width={"200"}></img>
    </div>
    <div>
        <h2>Marketing Packet</h2>
        <p>If you or a company you are affiliated with is interested in sponsoring our team, we have a Marketing Packet available for your viewing. The PDF outlines our team's past and goals, how we help the community, and ways & benefits of donating to our team.</p>
        
        <Button color = "primary" variant = "contained" target="_blank" href="https://drive.google.com/file/d/1s7wc0u2HNA8D4mPnVXOmPtAwsu6trrUq/view">View Now</Button>
        
        <img src="Images/Handshake.png"
        height={"200"}
        width={"190"}></img>
    </div>
    </>    
    )

}

export default SupportUs;