import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmailIcon from '@mui/icons-material/Email';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';


import React from "react";
const Footer = () =>{

    const [value, setValue] = React.useState("Recents")
    return (
    <BottomNavigation
    showLabels
    value={value}
    onChange={(event, newValue) => {
    setValue(newValue);
  }}
>
  <BottomNavigationAction label="Chesterfield, MO, USA" icon={<ContactMailIcon />}/>
  <BottomNavigationAction label="actionrobotix@gmail.com" icon={<EmailIcon />} />
  <BottomNavigationAction label="636-579-6518" icon={<PhoneIcon />} ></BottomNavigationAction>
  <BottomNavigationAction label="" icon={<InstagramIcon />} />
  <BottomNavigationAction label="" icon={<XIcon />} />
  <BottomNavigationAction label="" icon={<YouTubeIcon />} />

</BottomNavigation>)
}

export default Footer;