import React from "react";
const Outreach = () => {

    return (<>
        
        <div>
            <h1>Outreach Events</h1>
            <h3>At Action Robotix, our #1 priority is connecting with the community! Here's how we help out locally:</h3>
        </div>
        <br />
        <div>
            <h2>FLL Team Mentoring</h2>
            <p>Action Robotix regularly engages with the community through coaching and mentoring FLL teams. Currently, we are mentoring 2 teams: The Eager Eagles (58002) and Action Robotics (52183).</p>
            <img src="Images/FLLMentor.jpeg"
            height={"450"}
            width={"600"}></img>
        </div>
        <div>
            <h2>Magic House Event</h2>
            <p>Throughout the season, we travel to events to share our love for robotics and inspire that same feeling in the STL region. A recent event we visited was at the Magic House in St. Louis.</p>
            <img src="Images/magichouseevent.jpg"
            height={"600"}
            width={"450"}></img>
        </div>
    </>
    )

}

export default Outreach;