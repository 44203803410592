import { Button } from "@mui/material";

const FIRST = () => {


    return (
    <>
    <div>
        <h1>FIRST</h1>
        <br></br>
        <h2>An Overview of FIRST Robotics</h2>
        <p>The FIRST® (For Inspiration and Recognition of Science and Technology) organization is a global nonprofit that aims to inspire young people to become leaders and innovators in science, technology, engineering, and mathematics (STEM). Founded in 1989 by inventor Dean Kamen, FIRST engages students in grades K-12 through team-based, mentor-guided robotics programs that build skills in engineering, problem-solving, teamwork, and communication.


FIRST offers several programs tailored to different age groups:


1. FIRST LEGO League (FLL): For ages 4-16, FLL introduces younger students to STEM through hands-on, collaborative robotics projects using LEGO kits. The program is divided into three divisions: Discover (ages 4-6), Explore (ages 6-10), and Challenge (ages 9-16).


2. FIRST Tech Challenge (FTC): For students in grades 7-12, FTC teams design, build, and program robots to compete in head-to-head challenges on a 12x12 field. This level encourages creativity and innovation, as students use various materials and advanced programming tools.


3. FIRST Robotics Competition (FRC): For high school students in grades 9-12, FRC is the most advanced FIRST program. Teams are given a new challenge each year, and within a six-week build period, they design and construct industrial-sized robots to compete in alliances with other teams.


Through these programs, FIRST promotes a culture of "Gracious Professionalism" and "Coopertition®," where teams work together and support each other, even while competing. With opportunities for scholarships, networking, and skills development, FIRST provides young people a pathway into STEM careers and personal growth, fostering an enduring passion for innovation and learning. </p>
    
    </div>
    <div>
        <h2>FIRST Lego League</h2>
        <Button color = "primary" variant = "contained">Learn More about FLL</Button>
        <img src = "Images/fllPicture.avif"
        height="200"></img>

    </div>
    <div>
        <h2>FIRST Tech Challenge</h2>
        <Button color = "primary" variant = "contained">Learn More about FTC</Button>
        <img src = "Images/ftcRobot.jpeg"
        height="200"></img>

    </div>
    <div>
        <h2>FIRST Robotics Competition</h2>
        <Button color = "primary" variant = "contained">Learn More about FRC</Button>
        <img src = "Images/frcPicture.jpeg"
        height="200"></img>
    </div>
    
    </>
    )
    
}

export default FIRST;